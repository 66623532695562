import React, { useState } from 'react'
import { FaAngleUp } from "react-icons/fa6";
import { Link } from 'react-router-dom';
import { BiSolidPencil } from "react-icons/bi";
import { FaListUl } from "react-icons/fa6";
import { HiClipboardList } from "react-icons/hi";
import { GiBlackBook } from "react-icons/gi";
import { BsBookFill } from "react-icons/bs";


const DropdownForSideNav = ({
    z,
    tab,
    PathName
}) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleDropdown = () => {
        setIsOpen((prev) => !prev);
    };




    return (
        <div className="relative select-none">
            <div
                onClick={toggleDropdown}
                className={`flex items-center text-[0.8rem] py-2 px-4 TealLink rounded cursor-pointer duration-150 ${isOpen ? 'rounded-b-none' : ''}`}
            >
                {tab?.icon}
                {tab?.label}
                <FaAngleUp
                    className={`ml-auto w-[0.8rem] h-[0.8rem] transition-transform duration-200 ${isOpen ? 'rotate-180' : 'rotate-0'}`}
                />
            </div>
            <div
                className={`overflow-hidden transition-all duration-300 ease-in-out transform origin-top ${isOpen ? 'h-auto' : 'h-0'}`}
            >
                <div className="TealLink rounded-b">
                    {tab.tabChildren?.map((child, index) => (
                        <Link
                            draggable={false}
                            key={child.label}
                            to={`${child.href}`}
                            className={`flex text-[0.8rem] items-center py-2 px-4 duration-150 select-none ${PathName === child.href ? "bg-[#0d9488]" : "bg-[#0f766e]"}
                                ${tab.tabChildren && index === tab.tabChildren.length - 1 ? 'rounded-b' : ''} `}
                        >
                            {child?.icon}
                            {child?.label}
                        </Link>
                    ))}
                </div>
            </div>
        </div>
    );
};

export const sideBarNavTabs = [
    {
        label: 'Assessments',
        icon: <HiClipboardList className="mr-3 h-4 w-4" />,
        tabChildren: [
            {
                label: 'New Assessment',
                href: '/CreateAssessment',
                icon: <BiSolidPencil className="mr-3 h-4 w-4" />
            },
            {
                accessRoles: ['super-admin', 'hr'],
                type: 'link',
                label: 'Assessment List',
                href: '/Assessments',
                icon: <FaListUl className="mr-3 h-4 w-4" />
            }
        ]
    },
    {
        label: 'Library',
        icon: <BsBookFill className="mr-3 h-4 w-4" />,
        tabChildren: [
            {
                label: 'Book 1',
                href: '/assessments/create',
                icon: <GiBlackBook className="mr-3 h-4 w-4" />
            }
        ]
    }
];
export default DropdownForSideNav
