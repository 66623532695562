import React from 'react'
import { Oval } from 'react-loader-spinner'
import './Loading.scss'

export default function Loading() {
    return <Oval
        visible={true}
        height="80"
        width="80"
        color="#036666"
        ariaLabel="oval-loading"
        wrapperStyle={{}}
        wrapperClass="Loader"
        secondaryColor='#44546A'
    />
}
