import React, { useContext } from 'react'
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom'
import bayanLogo from '../../assets/images/bayan-consulting.png'
import { RiContactsFill } from "react-icons/ri";
import { BsBookFill } from "react-icons/bs";
import { RiLogoutBoxLine } from "react-icons/ri";
import { IoMdSettings } from "react-icons/io";
import DropdownForSideNav from '../../components/Dropdown';
import { IoIosNotifications } from "react-icons/io";
import PhaseProvider from '../../Context/PhaseContext';
import { useCookies } from 'react-cookie';
import { HiClipboardList } from 'react-icons/hi';
import { BiSolidPencil } from 'react-icons/bi';
import { FaListUl } from 'react-icons/fa6';
import { GiBlackBook } from 'react-icons/gi';
import { FaChalkboardTeacher } from "react-icons/fa";
import { RiAdminFill } from "react-icons/ri";
import { MdOutlinePeopleAlt } from "react-icons/md";
import { IoMdPersonAdd } from "react-icons/io";
import { IoPeople } from "react-icons/io5";
import { BsPersonRaisedHand } from "react-icons/bs";
import { BsPersonStanding } from "react-icons/bs";
import { FaPeopleGroup } from "react-icons/fa6";
import { RoleContext } from '../../Context/RoleContext';
import { PiExamFill } from "react-icons/pi";

export default function MainLayout() {

    const { pathname } = useLocation();
    const [, , removeCookie] = useCookies(['ElBayan']);
    const navigate = useNavigate()

    const { Role } = useContext(RoleContext)


    const LogOut = () => {
        removeCookie('ElBayan');
        navigate('/Auth');
    }

    console.log(pathname);


    return <>
        <div className="flex flex-col w-full lg:flex-row min-h-screen bg-gray-100">
            <div className="flex flex-col min-h-max  lg:min-h-screen lg:pl-6 pb-6">
                <div className="h-[4rem] my-5 lg:flex justify-center items-center hidden">
                    <img
                        src={bayanLogo}
                        alt="Bayan Consulting Logo"
                        width={200}
                    />
                </div>
                <aside className="w-full lg:rounded-md lg:w-56 lg:h-full Teal text-white p-6 flex flex-col ">
                    <nav className="space-y-4 flex-grow">

                        <DropdownForSideNav PathName={pathname} tab={{
                            label: 'Assessments',
                            icon: <HiClipboardList className="mr-3 h-4 w-4" />,
                            tabChildren: [
                                {
                                    label: 'New Assessment',
                                    href: '/CreateAssessment',
                                    icon: <BiSolidPencil className="mr-3 h-4 w-4" />
                                },
                                {
                                    label: 'Assessment List',
                                    href: '/',
                                    icon: <FaListUl className="mr-3 h-4 w-4" />
                                }
                            ]
                        }} />

                        {(Role === "super-admin" || Role === "admin") && <Link
                            to='/Contacts'
                            className={`flex text-[0.8rem] items-center py-2 px-4 duration-150
                            rounded ${pathname === "/Contacts" ? "bg-[#0d9488]" : "bg-[#0f766e]"}`}>
                            <RiContactsFill
                                className="mr-3 h-4 w-4"
                            />
                            Contacts
                        </Link>}


                        <DropdownForSideNav PathName={pathname} tab={{
                            label: 'Library',
                            icon: <BsBookFill className="mr-3 h-4 w-4" />,
                            tabChildren: [
                                {
                                    label: 'All Tests',
                                    href: '/Tests',
                                    icon: <FaChalkboardTeacher className="mr-3 h-4 w-4" />
                                },
                                {
                                    label: 'New Test',
                                    href: '/TestCreation',
                                    icon: <GiBlackBook className="mr-3 h-4 w-4" />
                                }
                            ]
                        }} />

                        <DropdownForSideNav PathName={pathname} tab={{
                            label: 'Candinates',
                            icon: <BsPersonRaisedHand className="mr-3 h-4 w-4" />,
                            tabChildren: [
                                {
                                    label: 'All Candinates',
                                    href: '/AllCandinates',
                                    icon: <FaPeopleGroup className="mr-3 h-4 w-4" />
                                },
                                {
                                    label: 'New Candinates',
                                    href: '/CandinateCreation',
                                    icon: <BsPersonStanding className="mr-3 h-4 w-4" />
                                }
                            ]
                        }} />


                        {Role === "super-admin" && <DropdownForSideNav PathName={pathname} tab={{
                            label: 'Admins',
                            icon: <RiAdminFill className="mr-3 h-4 w-4" />,
                            tabChildren: [
                                {
                                    label: 'All Admins',
                                    href: '/AllAdmins',
                                    icon: <MdOutlinePeopleAlt className="mr-3 h-4 w-4" />
                                },
                                {
                                    label: 'Create Admin',
                                    href: '/CreateAdmin',
                                    icon: <IoMdPersonAdd className="mr-3 h-4 w-4" />
                                }
                            ]
                        }} />}


                        {(Role === "super-admin" || Role === "admin") && <Link
                            to='/AllHRs'
                            className={`flex text-[0.8rem] items-center py-2 px-4 duration-150
                            rounded ${pathname === "/AllHRs" ? "bg-[#0d9488]" : "bg-[#0f766e]"}`}>
                            <IoPeople
                                className="mr-3 h-4 w-4"
                            />
                            Human Resource
                        </Link>}

                    </nav>
                    <div className="mt-auto pt-6 space-y-4">
                        <Link
                            to="#"
                            className=" TealLink text-[0.8rem] rounded  duration-150 flex items-center py-2 px-4"
                        >
                            <IoMdSettings
                                className="mr-3 h-4 w-4"
                            />
                            Settings
                        </Link>
                        <button
                            className="TealLink text-[0.8rem] rounded w-full duration-150 flex items-center py-2 px-4"
                            onClick={LogOut}
                        >
                            <RiLogoutBoxLine
                                className="mr-3 h-4 w-4"
                            />
                            Logout
                        </button>
                    </div>
                </aside>
            </div>
            <main className="flex-1 p-6 h-screen overflow-auto">
                <header className="flex justify-end items-center mb-8">
                    <div className="flex items-center space-x-4">
                        <button

                        >
                            <IoIosNotifications
                                className="h-5 w-5"
                            />
                        </button>
                        <div>
                            <p className="font-semibold text-sm">{Role}</p>
                        </div>
                    </div>
                </header>
                <PhaseProvider>
                    <Outlet></Outlet>
                </PhaseProvider>
            </main>
        </div>
    </>
}
