import React from 'react'
import { useContext } from 'react'
import { RoleContext } from '../Context/RoleContext'
import { Navigate } from 'react-router-dom'

export default function SuperAdmin(props) {
    const { Role } = useContext(RoleContext)


    if (Role === "super-admin") {
        return props.children
    } else {
        return <Navigate to={"/"} />

    }

}
