import React from 'react'
import './NotFound.scss'
import NotFoundImage from '../../../assets/images/undraw_no_data_re_kwbl.svg'
import { Link } from 'react-router-dom'

export default function NotFound() {
    return <section className='fixed w-full h-full flex justify-center items-center'>

        <div className="NotFound">
            <div className="w-full flex justify-center items-center">
                <img src={NotFoundImage} alt="Not Found" />
            </div>
            <div className="w-full flex justify-center items-center mt-4">
                <h1>Not Found || <Link to={'/'}>Home Page</Link></h1>
            </div>
        </div>

    </section>

}
