import React from 'react';
import './Report.scss';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { useQuery } from 'react-query';
import Loading from '../../../components/Loading/Loading';
import { useCookies } from 'react-cookie';
import { BaseUrl } from '../../../helpers/Api';
import AdminAssestments from './AdminAssestments';

export default function AdminReport() {

    const { id } = useParams();
    const [Cookies] = useCookies(['ElBayan']);

    const GetAdminDetails = async () => {
        const response = await axios.get(`${BaseUrl}/v1/super-admin/admin/${id}`, {
            headers: {
                authorization: Cookies?.ElBayan && `Bearer ${Cookies?.ElBayan}`
            }
        });
        return response.data?.data?.admin
    }

    const { data: AdminDetails, isLoading } = useQuery("Admin Details", GetAdminDetails, {
        cacheTime: 0
    })

    if (isLoading) return <Loading />

    return <section className='Report p-6'>
        <h1 className='text-xl'>Admin Report</h1>

        <div className="w-full my-5 Information">
            <h2>Information</h2>
            <div className="Information_Details  mt-3">
                <div className="w-full flex justify-between items-center">
                    <h3>Name</h3>
                    <p>{AdminDetails[0]?.name}</p>
                </div>
                <div className="w-full flex justify-between items-center">
                    <h3>Email</h3>
                    <p>{AdminDetails[0]?.email}</p>
                </div>
                <div className="w-full flex justify-between items-center">
                    <h3>Is Verified ?</h3>
                    <p>{AdminDetails[0]?.isVerified ? "Verified" : "Not Verified"}</p>
                </div>
            </div>

        </div>

        {(AdminDetails[0]?.assessments?.length === 0 && AdminDetails[0]?.candidates?.length === 0)
            && <div className="p-4 mb-4 mt-2 text-sm text-black rounded-lg bg-[#F5F5F5]" role="alert">
                <span className="font-medium">For noticed</span> This Admin Have not made any action on website yet.
            </div>}




        {AdminDetails[0]?.assessments?.length === 0 ? null : <div className="mt-5">
            <h1>All Assestments</h1>
            <div className="my-4">
                <AdminAssestments Assestments={AdminDetails[0]?.assessments} Assestment={true} />
            </div>
        </div>}

        {AdminDetails[0]?.candidates?.length === 0 ? null : <div className="mt-5">
            <h1>All Candidates</h1>
            <div className="my-4">
                <AdminAssestments Assestments={AdminDetails[0]?.candidates} Assestment={false} />
            </div>
        </div>}


    </section>

}
