import React from 'react'
import { useContext } from 'react'
import { RoleContext } from '../Context/RoleContext'
import { Navigate } from 'react-router-dom'

export default function ExamProtector(props) {
    const { Assestment } = useContext(RoleContext)


    if (!Assestment) {
        return props.children
    } else {
        return <Navigate to={"/Exam/Submitted"} />

    }

}
