import React from 'react'
import Rating from './../assets/images/Asset 1.png'
import Rating2 from './../assets/images/Asset 2.png'
import Rating3 from './../assets/images/Asset 3.png'

export default function LevelImage({ Level, style }) {

    if (Level === "Mid-Level" || Level === "Intermediate")
        return <img src={Rating2} alt="Rate" loading='lazy' className='h-[28px] w-[34.63px]' style={style} />

    if (Level === "Advanced")
        return <img src={Rating3} alt="Rate" loading='lazy' className='h-[28px] w-[34.63px]' style={style} />


    return <img src={Rating} alt="Rate" loading='lazy' className='h-[28px] w-[34.63px]' style={style} />
}
