import React from 'react';
import './Report.scss';
// import { useFormik } from 'formik';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import { BaseUrl } from '../../../helpers/Api';
import { useQuery } from 'react-query';
import Loading from '../../../components/Loading/Loading';
import { useCookies } from 'react-cookie';

export default function Report() {

    const { id } = useParams();
    const [Cookies] = useCookies(['ElBayan']);

    // const formik = useFormik({
    //     initialValues: {
    //         feedback: ""
    //     }
    // })

    const GetCandidateDetails = async () => {
        const reponse = await axios.get(`${BaseUrl}/v1/candidate/${id}`, {
            headers: {
                authorization: Cookies?.ElBayan && `Bearer ${Cookies?.ElBayan}`
            }
        });

        console.log(reponse.data);


        return reponse.data
    }

    const { data, isLoading } = useQuery("Get Candidate Details", GetCandidateDetails, {
        cacheTime: 0
    });

    if (isLoading) return <Loading />

    return <section className='Report p-6'>
        <h1 className='text-xl'>Candidate Report</h1>

        <div className="w-full my-5 Information">
            <h2>Information</h2>
            <div className="Information_Details  mt-3">
                <div className="w-full flex justify-between items-center">
                    <h3>Name</h3>
                    <p>{data?.candidate?.name}</p>
                </div>
                <div className="w-full flex justify-between items-center">
                    <h3>Email</h3>
                    <p>{data?.candidate?.email}</p>
                </div>
                <div className="w-full flex justify-between items-center">
                    <h3>Phone</h3>
                    <p>{data?.candidate?.phone}</p>
                </div>
            </div>

        </div>


        {data?.assessmentResults?.length === 0 && <div className="p-4 mb-4 mt-2 text-sm text-black rounded-lg bg-[#F5F5F5]" role="alert">
            <span className="font-medium">For noticed</span> The candidate has not taken exams.
        </div>
        }


        {data?.assessmentResults?.map((result, index) => <div key={index}>

            <h1 className='my-1'>Assestment {index + 1}</h1>
            <div className="ReportCards w-full flex flex-wrap items-center gap-3 my-5">
                <div className="ReportCard">
                    <h2>Candidate Score</h2>
                    <p>{result?.score}</p>
                </div>
                <div className="ReportCard">
                    <h2>Assestment Score</h2>
                    <p>{result?.totalScore}</p>
                </div>
                <div className="ReportCard">
                    <h2>Total Precentage</h2>
                    <p>{parseInt(result?.percentage)} %</p>
                </div>
                <div className="ReportCard">
                    <h2>Candidate stage</h2>
                    <p>{result?.status}</p>
                </div>
            </div>
            {/* <div className="w-full">
                <h1>Feedback</h1>
                <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi sapiente est ea omnis ipsum
                    labore tenetur quaerat totam aliquid, consequatur voluptates amet culpa id autem quis.
                    Minus magnam voluptas ipsum.
                </p>
            </div> */}
            {/* ResultDetails/Assestment/:AssestmentId/Candidate/:CandidateId */}
            <Link to={`/ResultDetails/Assestment/${result?.assessmentId}/Candidate/${id}`} className='bg-primary-700 text-white px-4 text-sm py-1
            rounded-lg relative top-[10px] shadow'>Check Result</Link>
            <hr className="h-px my-6 bg-gray-200 border-0" />
        </div>
        )}


        {/* <div className="FeedBack">
            <h4>Feedback</h4>
            <form onSubmit={formik.handleSubmit}>
                <div className="">
                    <textarea id="message" rows="5" name='feedback' onChange={formik.handleChange} onBlur={formik.handleBlur}
                        className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border
                 border-gray-300 focus:ring-primary-500 focus:border-primary-500" placeholder="Candidate Feedback"></textarea>
                </div>

                <div className="w-full flex justify-end">
                    <button type="submit" className="focus:outline-none text-white bg-primary-700 hover:bg-primary-800 focus:ring-4
                 focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2">Send Feedback</button>
                </div>
            </form>
        </div> */}
    </section>

}
