import { createContext, useState } from "react";

export const RoleContext = createContext();

export default function RoleProvider({ children }) {
    const [Role, setRole] = useState();

    const [Assestment, setAssestment] = useState()


    return (
        <RoleContext.Provider value={{
            Role, setRole, Assestment, setAssestment
        }}>
            {children}
        </RoleContext.Provider>
    );
}
