import { createContext, useState } from "react";

export const PhaseContext = createContext();

export default function PhaseProvider({ children }) {
    const [Phase, setPhase] = useState(0);

    const [TotalRole, setTotalRole] = useState();

    const [TestType, setTestType] = useState([]);

    const [QuestionLength, setQuestionLength] = useState(1);

    const [QuestionsContent, setQuestionsContent] = useState([{}]);

    const [QuestionType, setQuestionType] = useState("Qualifier")

    return (
        <PhaseContext.Provider value={{
            Phase, setPhase, TotalRole, setTotalRole,
            TestType, setTestType, QuestionLength, setQuestionLength,
            QuestionsContent, setQuestionsContent, QuestionType, setQuestionType
        }}>
            {children}
        </PhaseContext.Provider>
    );
}
